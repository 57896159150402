import React from "react"

import Layout from "../components/layout"
import DesktopPoligono from "../components/poligonos/desktop/poligono";
import MobilePoligono from '../components/poligonos/mobile/Poligono'
import '../components/heliofania/styles.scss';

import SimpleLocalize from "../components/SimpleLocalize";

import poligono13imgelectroconductividad from "../images/poligonos/poligono_13_02.jpg"
import poligono13imgpoligono from "../images/poligonos/poligono_13_03.jpg"
import poligono13vinos from "../images/poligonos/vinos_poligono-13.jpg"

import poligono5imgelectroconductividad from "../images/poligonos/poligono5_02.jpg"
import poligono5imgpoligono from "../images/poligonos/poligono5_03.jpg"
import poligono5vinos from "../images/poligonos/vinos_poligono-5.jpg"

import poligono1imgelectroconductividad from "../images/poligonos/poligono1_02.jpg"
import poligono1imgpoligono from "../images/poligonos/poligono1_03.jpg"
import poligono1vinos from "../images/poligonos/vinos_poligono-1.jpg"

import { BrowserView, MobileView } from 'react-device-detect';
import DesktopVideoHeader from "../components/poligonos/desktop/VideoHeader";
import MobileVideoHeader from '../components/poligonos/mobile/VideoHeader';
import DesktopSection2 from '../components/poligonos/desktop/Section2';
import MobileSection2 from '../components/poligonos/mobile/Section2'

const IndexPage = (props) => {
	const { pageContext } = props;

	let camino = '..'
	let poslang = '';
    if(pageContext.language === 'en'){
        camino = '../..';
		poslang = "_en";
    }

	const poligonos = [
		{
			titulo: `<span>${pageContext.messages["poligono-trece"] }</span> <em>${pageContext.messages["malbec"] }</em>`,
			texto: pageContext.messages["nuestro-suelo-tipo-paraje-altamira"],
			ubicacion: { img: `${camino}/poligonos/poligono13_ubicacion_47${poslang}.jpg`, texto: pageContext.messages["pasaje-altamira-cuartel-47"] },
			electroconductividad: { img: poligono13imgelectroconductividad, texto: pageContext.messages["electrocunductividad-cuartel-cientocincuenta"] },
			poligono: { titulo: `${pageContext.messages["poligono-trece"]} - ${pageContext.messages["malbec"] }`, img: poligono13imgpoligono, texto: pageContext.messages["superficie1coma2"]  },
			vino: { titulo: pageContext.messages["vino"], img: poligono13vinos, texto: pageContext.messages["cien-por-cien-Malbec"] },
		},
		{
			titulo:  `<span>${pageContext.messages["poligono-cinco"] }</span> <em>${pageContext.messages["cabernet-franc"] }</em>`,
			texto: pageContext.messages["nuestro-suelo-de-la-consulta"],
			ubicacion: { img: `${camino}/poligonos/poligono5_ubicacion_26${poslang}.jpg`, texto: pageContext.messages["las-consulta-cuartel-veintiseis"] },
			electroconductividad: { img: poligono5imgelectroconductividad, texto: pageContext.messages["electrocunductividad-del-cuartel-cientoveinte"] },
			poligono: { titulo: `${pageContext.messages["poligono-cinco"]} - ${pageContext.messages["cabernet-franc"] }`, img: poligono5imgpoligono, texto: pageContext.messages["superficie-una-hectarea"]},
			vino: { titulo: pageContext.messages["vino"], img: poligono5vinos, texto: pageContext.messages["cien-por-cien-cabernet-franc"] }
		},
		{
			titulo: `<span>${pageContext.messages["poligono-uno"] }</span> <em>${pageContext.messages["cabernet-sauvignon"] }</em>`,
			texto: pageContext.messages["para-nuestro-suelo-de-eugenio-bustos"],
			ubicacion: { img: `${camino}/poligonos/poligonos_ubicacion_23${poslang}.jpg`, texto: pageContext.messages["eugenio-bustos-cuartel-veintitres"] },
			electroconductividad: { img: poligono1imgelectroconductividad, texto: pageContext.messages["electrocunductividad-del-cuartel-cientoveinte"]},
			poligono: { titulo: `${pageContext.messages["poligono-uno"]} - ${pageContext.messages["cabernet-sauvignon"] }`, img: poligono1imgpoligono, texto: pageContext.messages["superficie-una-ha-varietal"] },
			vino: { titulo: pageContext.messages["vino"], img: poligono1vinos, texto:pageContext.messages["cien-por-ciento-cabernet-sauvignon"]}
		},
	];

	return <Layout page='poligonos' {...props}>
		<SimpleLocalize {...props}>

			<BrowserView>
				<DesktopVideoHeader {...props} />
				<DesktopSection2 {...props} />
				{poligonos.map((pol, index) => {
					return <DesktopPoligono key={index} {...props} dato={pol} />
				})}
			</BrowserView>
			<MobileView>
				<MobileVideoHeader {...props} />
				<MobileSection2 {...props} />
				{poligonos.map((pol, index) => {
					return <MobilePoligono key={index} {...props} dato={pol} />
				})}
			</MobileView>


		</SimpleLocalize>

	</Layout>
}

export default IndexPage